import React, { useState } from 'react'
import { Button, Checkbox, IconClose, Paragraph } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import { JWK, SimplifiedCredential } from '../../api/vce-api/vce-api';
import CheckFederation from '../../components/CheckFederation';
import { pid } from 'process';


export interface PidCredentialClaims {
  family_name: string
  given_name: string
  birth_date: string
}

export interface PidCredentialSdJwt extends PidCredentialClaims {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK
}

export interface PidCredentialVcJwt {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK

  vc?: {
    credentialSubject?: PidCredentialClaims
  }
  credentialSubject?: PidCredentialClaims
}

export function getPidClaims(credential: PidCredentialSdJwt|PidCredentialVcJwt): PidCredentialClaims {
  return (credential as PidCredentialVcJwt).credentialSubject || 
    (credential as PidCredentialVcJwt).vc?.credentialSubject ||
    (credential as PidCredentialSdJwt)
}

type Props = {
  credential: SimplifiedCredential
  onClear: () => void
}

const Pid = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  // TODO support other than sd-jwt:
  const vc = /*props.credential.vc?.credentialSubject ||*/ props.credential.payload as PidCredentialSdJwt|PidCredentialVcJwt;

  debugger;
  const claims = getPidClaims(vc);

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={visualize}
          onChange={() => setVisualize(old => !old)} />}
          label={t("credentialView.visualize")}
          labelPlacement="start"
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
         <Paragraph>
          <b>Given names:</b><br/>{ claims.given_name }
        </Paragraph>
        <Paragraph>
          <b>Family name:</b><br/>
          { claims.family_name }
        </Paragraph>
        <Paragraph>
          <b>Birth date:</b><br/>
          { claims.birth_date }
        </Paragraph>
        <Paragraph>
          <b>Issuer:</b><br/>
          { vc.iss }
        </Paragraph>
        
        <CheckFederation credential={props.credential} name='pid'></CheckFederation>
        <div style={{display: "flex", flexDirection: "row" }}>
          <span style={{flexGrow: 1}}></span>
          <Button variant='secondary' icon={<IconClose></IconClose>} onClick={ props.onClear }>Clear form</Button>
        </div>
        
      </div>
      
      }
    </Paper>
  )
}

export default Pid