import React, { useState } from 'react'
import { Button, Heading, IconClose } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import convertToArray from './convertToArray';
import { SimplifiedCredential } from '../../api/vce-api/vce-api';
import CheckFederation from '../../components/CheckFederation';


export interface EuccCredentialSdJwt {
  iss: string
  legalName: string
  legalFormType: string
  legalIdentifier: string
  registeredAddress: RegisteredAddress[]
  postalAddress: PostalAddress[]
  registrationDate: string
  shareCapital: ShareCapital[]
  legalEntityStatus: string
  legalRepresentative: LegalRepresentative[]
  legalEntityActivity: LegalEntityActivity[]
  contactPoint: ContactPoint[]
  issuer_name: string
  issuer_id: string
  issuer_country: string
  issuance_date: string
  expiry_date: string
  authentic_source_id: string
  authentic_source_name: string
}

export interface RegisteredAddress {
  post_code: string
  post_name: string
  thoroughfare: string
  locator_designator: string
}

export interface PostalAddress {
  post_code: string
  post_name: string
  thoroughfare: string
  locator_designator: string
}

export interface ShareCapital {
  amount: string
  currency: string
}

export interface LegalRepresentative {
  role: string
  legalEntityId: string
  scopeOfRepresentation: string,
  family_name?: string,
  given_name?: string,
  birth_date?: string
}

export interface LegalEntityActivity {
  code: string
  businessDescription: string
}

export interface ContactPoint {
  contactPage: string
  hasEmail: string
  hasTelephone: string
}


type Props = { 
  credential: SimplifiedCredential // EuccCredentialSdJwt
  onClear: () => void
}

const Eucc = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  const vc = props.credential.payload;

  //const userContext = useUserContext();

  const getDate = (datetime: string|Date|undefined): string => {
    if (datetime === undefined)
      return "";
    if (datetime instanceof Date) {
      return datetime.toUTCString().substring(0, 10);
    }
    return datetime.substring(0, 10);
  } 

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>

      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>

      { !visualize ? <pre style={{fontSize: "12px", overflowX: "scroll" }}>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
        <p>
          Issuer: { vc.iss }<br/>
          {/* Issuer Business ID: { vc.legalIdentifier.notation }<br/> */}
          Date and time of creating the document: { vc.issuance_date }<br/>
        </p>
        <Heading variant='h3'>EU Company Certificate (EUCC)</Heading>
        <br/>
        
        <table cellSpacing={0} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Identifiers</Heading>
            </th>
          </tr>
          <tr>
            <td title="Name of the company">Company name</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legalName}</td>
          </tr>
          {/* <tr>
            <td title="Finnish Business Identity Code">Business ID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.notation}</td>
          </tr> */}
          <tr>
            <td title="EU Business Identity Code">EUID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legalIdentifier}</td>
          </tr>
          <tr>
            <td title="Date on which the PRH has made the register entry">Registration date</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.registrationDate}</td>
          </tr>
          <tr>
            <td>Company type</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{ vc.legalFormType }</td>
          </tr>
          <tr>
            <td title="Postal address">Contact details:</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{convertToArray(vc.postalAddress)[0].post_code + " " + convertToArray(vc.postalAddress)[0].post_name}</td>
          </tr>
          {/* {vc.postalAddress.poBox && 
            <tr>
              <td title="Postal address">Postall address</td>
              <td style={{minWidth: "20px"}}></td>
              <td>PL {vc.postalAddress.poBox} {vc.postalAddress.postName}</td>
            </tr>
          } */}
          { convertToArray(vc.postalAddress)?.length &&
          <tr>
            <td title="Street address">Street address</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{convertToArray(vc.postalAddress)[0].thoroughfare}</td>
          </tr>
          }
        </tbody>
      </table>

      <br/>

      <table cellSpacing={0} style={{textAlign: "left"}}>
        <tbody>
        <tr>
          <th colSpan={3}>
            <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Share capital</Heading>
          </th>
        </tr>

        { vc.shareCapital && convertToArray<ShareCapital>(vc.shareCapital).map((shareCapital, i) => 
          <tr key={i}>
            <td title="Amount of share capital">Share capital</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{ shareCapital.amount } { shareCapital.currency }</td>
          </tr>)
        }
        </tbody>
      </table>

      <br/>

      <table cellSpacing={0} width={"100%"} style={{textAlign: "left"}}>
        <tbody>
        <tr>
          <th colSpan={3}>
            <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Persons</Heading>
          </th>
        </tr>
        <tr>
          <th>role</th>
          <th>person or company name</th>
          <th>date of birth / identity code</th>
          {/* <th>citizenship</th> */}
        </tr>
        { vc.legalRepresentative && convertToArray<LegalRepresentative>(vc.legalRepresentative).map((legalReprestative, i) => 
            <tr key={legalReprestative.legalEntityId}>
            <td>{ legalReprestative.role }</td>
            <td>{ legalReprestative.family_name } { legalReprestative.given_name }</td>
            <td>{ getDate(legalReprestative.birth_date) } / { legalReprestative.legalEntityId }</td>
            {/* <td>
              Citizen of ?
            </td> */}
          </tr>)
        }

        </tbody>
      </table>

      <br/>

      <table cellSpacing={0} style={{textAlign: "left"}}>
        <tbody>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Issuer</Heading>
            </th>
          </tr>
          <tr>
            <td title="Name of the issuer">Issuer name</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.issuer_name}</td>
          </tr>
          <tr>
            <td title="Issuer country">Issuer country</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.issuer_country}</td>
          </tr>
          <tr>
            <td title="Issuer id">Issuer id</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.issuer_id}</td>
          </tr>
          <tr>
            <td title="Issuer url">Issuer url</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.iss}</td>
          </tr>
        </tbody>
      </table>
      
      <br/>

      <table cellSpacing={0}>
        <tbody>
          <tr>
            <td style={{verticalAlign: "center"}}>Date of issuance</td><td style={{width: "40px"}}></td>
            <td style={{verticalAlign: "center"}}>E-signature</td>
          </tr>
          <tr>
            <td>
              <div style={{borderBottom: "1px solid black"}}>{ vc.issuance_date ||new Date().toLocaleDateString(/*selectedLanguage*/)}</div>
            </td>
            <td></td>
            <td></td>
            {/* <td><div style={{borderBottom: "1px solid black"}}><a href="#" rel="noreferrer" target="_blank" title={vc.legalName}></a></div></td> */}
          </tr>
        </tbody>
      </table>
      <br/>
      <CheckFederation credential={props.credential} name='eucc'></CheckFederation>
      <div style={{display: "flex", flexDirection: "row", marginTop:"10px"}}>
        <span style={{flexGrow: 1}}></span>
        <Button variant='secondary' icon={<IconClose></IconClose>} onClick={ props.onClear }>Clear form</Button>
      </div>
    </div> 
    }
    </Paper>
  )
}

export default Eucc