import React, { useEffect, useState } from 'react'
import vceApi from '../vceApi';
import { SimplifiedCredential } from '../api/vce-api/vce-api';
import { useQuery } from '@tanstack/react-query';
import { Paragraph } from 'suomifi-ui-components';

type Props = {
  credential: SimplifiedCredential
  name: string
}

const CheckFederation = (props: Props) => {
  const verifierName = "verifier";

  //const [ trusted, setTrusted ] = useState<boolean|undefined>(undefined); 
  //const [ trustedFederations, setTrustedFederations ] = useState<string[]>([]); 

  const { isPending, error, data } = useQuery({
    queryKey: [props.name],
    queryFn: () =>
      vceApi.verifiers.isIssuerTrustedByFederation(verifierName, {
        issuer: props.credential.issuer, 
        jwk: JSON.stringify(props.credential.issuerKey)
      }).then((x) => {
        //setTrusted(x.data.isTrustedByVerifier);
        //setTrustedFederations(x.data.issuerTrustedByFederations);
        return x.data
      }
    )
  })


  
  const trusted = data?.isTrustedByVerifier;
  const localStorageName = `trusted-${props.name}-federations`;
  const trustedFederations = !isPending && data ? data.issuerTrustedByFederations.filter(x => data.verifierTrustedFederations.indexOf(x)>=0) : [];

  if (!isPending) {
    debugger;
    localStorage.setItem(localStorageName, trustedFederations.join(","));
  }
  //localStorage.setItem(localStorageName, trustedFederations.join(","));

  return (
    <div>
      <hr style={{margin: "20px 0"}} />
      { !isPending &&
        <div>
          <Paragraph style={{ color: "gray" }}>Issuer is trusted by following federations: { data?.issuerTrustedByFederations.join(", ") ?? "no any federation" }</Paragraph>
          <Paragraph style={{ color: "gray" }}>Verifier trusts following federations: { data?.verifierTrustedFederations.join(", ") ?? "does not trust any federation" }</Paragraph>
        </div>
      }
      {data && trusted === true && <span title={trustedFederations.join(", ")} style={{ color: "#00AA00" }}>&#x21D2; Issuer is trusted by the federation</span>}
      {data && trusted === false && <span style={{ color: "red" }}>&#x21D2; Issuer is not trusted by the verifier.</span>}
      {trusted === undefined && <span>Checking is issuer trusted by the trusted federation</span>}
    </div>
  )
}

export default CheckFederation