import React, { useState } from 'react'
import { Button, Heading, IconClose, Paragraph } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import { JWK, SimplifiedCredential } from '../../api/vce-api/vce-api';
import CheckFederation from '../../components/CheckFederation';


export interface PoaCredentialClaims {
  birth_date: string,
  given_name: string
  family_name: string,
  scope: string // "create a bank account"
  joint_type: string
  number_of_mandatees: number
  financial_rule_amount: number
  financial_rule_currency: string
  financial_rule_frequency: string
  issuer_name: string
}

export interface PoaCredentialSdJwt extends PoaCredentialClaims {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK
}

export interface PoaCredentialVcJwt {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK

  vc?: {
    credentialSubject?: PoaCredentialClaims
  }
  credentialSubject?: PoaCredentialClaims
}

export function getPidClaims(credential: PoaCredentialSdJwt|PoaCredentialVcJwt): PoaCredentialClaims {
  return (credential as PoaCredentialVcJwt).credentialSubject || 
    (credential as PoaCredentialVcJwt).vc?.credentialSubject ||
    (credential as PoaCredentialSdJwt)
}

type Props = {
  credential: SimplifiedCredential//PoaCredentialSdJwt|PoaCredentialVcJwt 
  onClear: () => void
}

const Poa = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  // TODO support other than sd-jwt:

  const claims = getPidClaims(props.credential.payload as PoaCredentialSdJwt|PoaCredentialVcJwt);

  const lpid = JSON.parse(localStorage.getItem("lpid")!) as SimplifiedCredential;
  debugger;
  const lpidCompanyKey = lpid?.payload.cnf.jwk;

  // quick compare jwk's
  const isValid = lpidCompanyKey && lpidCompanyKey.x === props.credential.issuerKey?.x && 
    lpidCompanyKey?.y === props.credential.issuerKey?.y &&
    lpidCompanyKey.x5c === props.credential.issuerKey?.x5c &&
    lpidCompanyKey.x5u === props.credential.issuerKey?.x5u &&
    lpidCompanyKey.crv === props.credential.issuerKey?.crv

  localStorage.setItem("poaSelfSigned", isValid ? "1" : "0")

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>

      <FormGroup>
        <FormControlLabel
          control={<Switch checked={visualize}
          onChange={() => setVisualize(old => !old)} />}
          label={t("credentialView.visualize")}
          labelPlacement="start"
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(props.credential, null, 2)}</pre> :
      <div>
        {/* <Heading variant='h4'>Given name</Heading> */}
        <Paragraph>
          <b>Given name</b><br/>
          {claims.given_name}
        </Paragraph>

        {/* <Heading variant='h4'>Family name</Heading> */}
        <Paragraph>
          <b>Family name</b><br/>
          {claims.family_name}
        </Paragraph>

        {/* <Heading variant='h4'>Date of birth</Heading> */}
        <Paragraph>
          <b>Date of birth</b><br/>
          {claims.birth_date}
        </Paragraph>

        {/* <Heading variant='h4'>Proxied permissions (scope)</Heading> */}
        <Paragraph>
          <b>Proxied permssions (scope)</b><br/>
          {claims.scope}
        </Paragraph>

        {/* <Heading variant='h4'>Joint type</Heading> */}
        <Paragraph>
          <b>Joint type</b><br/>
          {claims.joint_type}
        </Paragraph>

        <Paragraph>
          <b>Number of mandatees</b><br/>
          {claims.number_of_mandatees}
        </Paragraph>

        {/* <Heading variant='h4'>Financial rule</Heading> */}
        <Paragraph>
          <b>Financial rule</b><br/>
          {claims.financial_rule_amount} {claims.financial_rule_currency}
        </Paragraph>
        {/* <Heading variant='h4'>Financial rule amount</Heading>
        <Paragraph>
          {claims.financial_rule_amount} 
        </Paragraph>

        <Heading variant='h4'>Financial rule currency</Heading>
        <Paragraph>
          {claims.financial_rule_currency}
        </Paragraph>
        */}

        {/* <Heading variant='h4'>Financial rule frequency</Heading> */}
        <Paragraph>
          <b>Family rule frequency</b><br/>
          {claims.financial_rule_frequency??"none"}
        </Paragraph> 

        <Paragraph>
          <b>Issuer name</b><br/>
          {claims.issuer_name}
        </Paragraph> 

        <Paragraph>
          <b>Issuer key</b><br/>
          {JSON.stringify(props.credential.issuerKey??"")}
        </Paragraph> 

        <hr style={{margin: "20px 0"}}/>

        <Paragraph>
          <b>Company key from LPID</b><br/>
          {JSON.stringify(lpidCompanyKey??"")}
        </Paragraph>

        { isValid ? 
          <span style={{ color: "#00AA00" }}>PoA is self signed by the same company as in LPID</span> :
          <span style={{ color: "red" }}>PoA is not self signed by the same company as in LPID</span>
        }
    
        {/* <CheckFederation credential={props.credential} name='pid'></CheckFederation> */}
        <div style={{display: "flex", flexDirection: "row" }}>
          <span style={{flexGrow: 1}}></span>
          <Button variant='secondary' icon={<IconClose></IconClose>} onClick={ props.onClear }>Clear form</Button>
        </div>
        
      </div>
      }
    </Paper>
  )
}

export default Poa